import BaseModel from "../../../../base/model/BaseModel";
import store from "@/store";
export default class Overtime extends BaseModel {
  constructor() {
    super();
    this.employee = store.state.auth.user.id;
    this.workplan = null;
    this.overtimeInHours = null;
    this.overtimeInMinutes = null;
    this.reason = null;
    this.period = null;
  }
}
